import React, { useRef, useEffect } from 'react';
import './Section.css';
import juiceBagImage from './images/juicebag.png'; // Ensure the path is correct
import juiceGlasDrinkImage from './images/juiceglasdrink.png'; // Ensure the path is correct

function Section5() {
  const sectionRef = useRef(null);
  const juiceBagContainerRef = useRef(null);
  const juiceGlasDrinkContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const juiceBagContainer = juiceBagContainerRef.current;
      const juiceGlasDrinkContainer = juiceGlasDrinkContainerRef.current;

      if (section && juiceBagContainer) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const scrollPosition = window.scrollY + window.innerHeight;

        console.log('Scroll Position:', scrollPosition, 'Section Top:', sectionTop, 'Section Height:', sectionHeight);

        if (scrollPosition > sectionTop && scrollPosition < sectionTop + sectionHeight) {
          const translateY = Math.min(150, (scrollPosition - sectionTop) / 5); // Adjust '50' and '5' as needed
          juiceBagContainer.style.top = `calc(70% - ${translateY}px)`; // Moves up as you scroll
          console.log('Translate Y:', translateY);
        }
        if (section && juiceGlasDrinkContainer) {
          const translateY = Math.min(150, (scrollPosition - sectionTop) / 5); // Adjust as needed
          juiceGlasDrinkContainer.style.top = `calc(30% + ${translateY}px)`; // Opposite movement
        }
      }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <section id="what" className="section5" ref={sectionRef}>
        <div className="juicebag-container" ref={juiceBagContainerRef}>
          <img src={juiceBagImage} alt="Juice Bag" className="juice-image" />
        </div>
  
        <div className="juiceglasdrink-container" ref={juiceGlasDrinkContainerRef}>
          <img src={juiceGlasDrinkImage} alt="Juice Glass Drink" className="juiceglasdrink-image" />
        </div>

      <h2 className="main-headline">What Can Juice Market Do for You?</h2>

      <div className="content-box">
        <h2 className="headline">The Dev's Orchard: Tools for Projects</h2>
        <p className="subtext">
          Imagine an orchard where development is as natural as growing fruit. With Juice Market, cultivate your project with ease. Deploy smart contracts, secure token locks, and conduct on-chain KYC—all automated by our ingenious bots. From price charting to profit displays on Telegram and Discord, our bots are the diligent bees to your blossoming venture.
        </p>
      </div>

      <div className="content-box">
        <h2 className="headline">Trading & On-Chain Analytics: The Juicer's Edge</h2>
        <p className="subtext">
          Squeeze every drop of potential from the market with our comprehensive trading tools. Anticipate moves with AI-driven rug checkers, and support your trades with smart AI bots. Embrace the power of perpetual trading, on-chain profit checking, and more. Become a Juice Maker—fuel our ecosystem with your processing power and share in the zestful rewards.
        </p>
      </div>
    </section>
  );
}

export default Section5;
