import React, { useState } from 'react';
import './Section.css'; // Importing styles from Section.css

function Section3() {
  const [activeQuestion, setActiveQuestion] = useState(0); 

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: "Will there be a presale for the tokens?",
      answer: "No presale here! We're juicing things up differently. All tokens will be airdropped to our NFT holders. Our NFTs are the vital pulp of our ecosystem, eliminating the need for a traditional presale. Keep an eye on our social channels for snapshot updates and juicy news!"
    },
    {
      question: "Is an NFT or token necessary to use the Juice Market platform?",
      answer: "No squeeze, no juice! Our platform is permissionless and open to all. While tokens and NFTs are not required, they do squeeze out substantial benefits, like dramatically reduced costs. It’s a sweeter deal with them!"
    },
    {
      question: "Do you use external mint providers for your launch?",
      answer: "We prefer to blend our own juice! As part of our mission, we’re serving as an open-source, permissionless mint provider. Our launch will showcase our tech, setting the standard for decentralized launches."
    },
    {
      question: "Is your AI actually real?",
      answer: "Absolutely real and freshly squeezed! Our team of blockchain experts, ripe with experience, handcrafted our AI. We're not just a platform; we're a solution to the industry's sour spots, all made with juicy love."
    },
    {
      question: "Is the app necessary to earn rewards?",
      answer: "Yes, our app is the vessel for your rewards. As a Juice Maker, you'll be the zest in our decentralized layer, providing resources for a seamless experience. Remember, our rewards are mainly in ADA to keep our token from wilting under selling pressure."
    },
    {
      question: "How are rewards generated on Juice Market?",
      answer: "Our ecosystem thrives on the fees from our tools, paid in ADA. While our native token offers cost reductions, the bulk of the fees are reinvested into the ecosystem. It’s a cycle of growth, powered by our community’s engagement and resources."
    },
    {
      question: "Why do I need an NFT?",
      answer: "Our NFTs are the seeds of our ecosystem. By owning one, you're not just holding a digital asset; you're powering our network, processing transactions, and reaping the rewards. It's a juicy way to contribute and benefit!"
    }
  ];
  

  return (
    <section id="faq"  className="section3">
      <div className="faq-background-box">
        <h2 className="faq-heading">Frequently Asked Questions</h2>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className={`faq-item ${activeQuestion === index ? 'active' : ''}`} 
              onClick={() => toggleQuestion(index)}
            >
              <div className="faq-question">{faq.question}</div>
              {activeQuestion === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
        <p className="faq-subtext">Can't find the answer you're looking for? Reach out to our support team for more help.</p>
      </div>
    </section>
  );
}

export default Section3;
