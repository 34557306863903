import React from 'react';
import './Section.css'; // Assuming you have a common CSS file for all sections

function Section4() {
  return (
    <section className="section4">
      <h2 className="headline2">Main Juice Headline for Section 4</h2>
      <p className="subtext2">This is a placeholder for the subtext that provides more detail about the headline above. It's a brief introduction to the section's content.</p>
      <p className="subtext2">More detailed information can go here, expanding on the section's topic and offering further insight or instructions for the user.</p>
    </section>
  );
}

export default Section4;
