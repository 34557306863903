// App.js
import React from 'react';
import './App.css';
import Menu from './Menu'; // Import the Menu component
import HeroSection from './HeroSection';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Footer from './Footer';
import Section6 from './Section6';

function App() {
  return (
    <div className="app">
      <Menu />
      <HeroSection />
      <Section1 />
      <Section2 />
      <Section5 />
      <Section6 />
      <Section3 />
      <Section4 />
      <Footer />
    </div>
  );
}

export default App;
