// Section6.js
import React from 'react';
import './Section.css';
import gurke from './images/gurke.png';
import apfel from './images/apfel.png';
import zitrone from './images/zitrone.png';
import juicerimage from './images/juicerimage.png';
import showglass from './images/showglass.png';

function Section6() {
  return (
    <section id="start" className="section6">
      <div className="header-container">
        <h2>Step Into Our Orchard of Opportunity & Reap the Rewards</h2>
        <p>Join our vibrant ecosystem where your efforts in the decentralized world are amply rewarded with ADA. Bypass the volatility of token-based systems and indulge in the sweet, secure taste of success.</p>
      </div>
      <div className="steps-container">
        <div className="step">
          <div className="images-container">
            <img src={gurke} alt="Gurke" className="image gurke" />
            <img src={apfel} alt="Apfel" className="image apfel" />
            <img src={zitrone} alt="Zitrone" className="image zitrone" />
          </div>
          <div className="text-content">
            <h2>1. Mint Your Ingredients</h2>
            <p>Dive into our diverse marketplace and collect your unique blend of ingredients. Your journey starts with a flavorful mix that sets the base for your future endeavors.</p>
          </div>
        </div>

        <div className="step">
          <div className="images-container2">
            <img src={juicerimage} alt="Juicer" className="image juicerimage" />
          </div>
          <div className="text-content">
            <h2>2. Choose Your Juicer</h2>
            <p>Select from our range of Juicers, each tailored to your needs. From casual to professional setups, the right Juicer not only enhances flavors but also amplifies your rewards.</p>
          </div>
        </div>

        <div className="step">
          <div className="images-container">
            <img src={showglass} alt="Showglass" className="image showglass" />
          </div>
          <div className="text-content">
            <h2>3. Download App & Start Juicing</h2>
            <p>Immerse yourself in the juicing experience with our app. Simple, intuitive, and powerful — it's the gateway to maximizing your rewards and savoring the fruits of your labor.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section6;
