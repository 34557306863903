import React from 'react';
import './SharedSvgStyles.css'; // Assuming you have your shared styles here

function SvgThree() {
  return (
    <div>
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 233.05 233.05">
    <g className="hand">
    <rect className="orangered animated fadeOutDown infinite d2" x="161.03" y="137.47" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <rect className="orangered animated fadeOutDown infinite" x="146.53" y="113.99" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <path className="orangered" d="M166.04 73.03l48.838 38.47-38.472 48.838-48.837-38.472z"/>
  <path className="d animated swing infinite" d="M95.74 42.3l-8.48-6.94a7.19 7.19 0 0 0-3-2.53 7.27 7.27 0 0 0-7 .57 7.13 7.13 0 0 0-3.36 4.7l-11.36 25L36.17 73a7.28 7.28 0 0 0-4.24 9.35 7.28 7.28 0 0 0 9.35 4.24l12.79-4.8L64.5 77.9v.59l-18.66 7A7.26 7.26 0 0 0 50.9 99.1l12.79-4.8 2.73-1 .06.2-11 4.13a7.28 7.28 0 0 0-4.25 9.37 7.28 7.28 0 0 0 9.35 4.24l12.79-4.8.34-.13a39.36 39.36 0 1 0 22-64z"/>
  <path className="purple animated swing infinite" d="M73.1 116.46l32-75.27a8.85 8.85 0 0 0-4.67-11.57 8.85 8.85 0 0 0-11.57 4.67l-32 75.27-4 24.58z"/>
  <path className="d animated swing infinite" d="M103.92 67.85s-21 1.4-27.35 5.38S67.2 80.91 71 86.1s11.95 3 11.95 3l23.77-1.11 11.16 19.91"/>
  <path className="d  animated swing infinite" d="M193.15 138.81a32.73 32.73 0 0 1-46.15 0l-24.7-24.7a32.73 32.73 0 0 1 0-46.15 32.73 32.73 0 0 1 46.15 0l24.7 24.7a32.73 32.73 0 0 1 0 46.15z"/>
  <rect className="pattern animated swing infinite" x="137.49" y="83.15" width="65.27" height="65.27" rx="32.64" ry="32.64" transform="rotate(-45 170.13 115.78)"/></g>
   <path className="d draw" d="M54.2 134.52a45.13 45.13 0 0 0-7.2 8.61c-2 3.09-3.48 6.518-3.38 9.91a8.76 8.76 0 0 0 1.38 4.52 12.44 12.44 0 0 0 3.45 3.44 32.57 32.57 0 0 0 9.72 4.16c7 1.91 14.54 2.58 22 3s15 .4 22.1.07l13.22-.58-10.7 8.45c-4.43 3.5-8.78 7.12-13 10.5s-9.19 6.54-14 9.54-9.65 5.9-14.64 5.88 170 0 0 1-15.41 7.55l-.58-.81a170.87 "/>
</svg>
    </div>
  );
}

export default SvgThree;
