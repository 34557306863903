import React from 'react';
import './HeroSection.css'; // Specific styles for the Hero Section
import Logo1 from './Logo1.svg';
import Logo2 from './Logo2.svg';
import Logo3 from './Logo3.svg';
import Logo4 from './Logo4.svg';
import Logo5 from './Logo5.svg';
import Logo6 from './Logo6.webp';
import watermelonImage from './images/watermelon.png'; // Path to your watermelon image

function HeroSection() {
  return (
    <div className="heroSection">
      <div className="page-wrapper">
      <div className="watermelon-container">
        <img src={watermelonImage} alt="Watermelon" className="floating-watermelon" />
      </div>
      
         <div className="letter">Juice.Market</div>
          <div className="leftContainer">
            <h2>Squeeze the Future of DeFi</h2>
            <p>Satisfy your thirst for innovation by becoming a Juicer and exploring our bot-served, no-code orchard. In a permissionless marketplace, trade with AI-guarded precision or deploy and create your own Token, all with the flavor of on-chain KYC, AI-enhanced security, and beyond.</p>
            <p className="emphasis">Sip the future with the promise of perpetual trading on the horizon, all reinforced by in-built trust protocols. As a decentralized layer fueled by the very community it serves — you — build upon Cardano.</p>
            <div className="button-container">
                <button className="btn">Start Juicing</button>
                <button className="btn section-btn">Learn more</button>
            </div>
          </div>
          <div id="animation2">
            <div className="glass"></div>
            <div className="orange"></div>
            <div className="orange"></div>
            <div className="orangeHalf"></div>
            <div className="juicer">
              <div className="head"></div>
              <div className="lever"></div>
              <div className="press"></div>
              <div className="stand"></div>
              <div className="squeezer"></div>
              <div className="juice"></div>
              <div className="base"></div>
            </div>
            <div className="logoCarouselContainer">
            <h3>💪 Pressed & Enriched by Industry Extracts 🍹</h3>
                <div className="logoCarousel">
                    <img src={Logo1} alt="Partner Logo 1" className="partnerlogo" />
                    <img src={Logo2} alt="Partner Logo 2" className="partnerlogo" />
                    <img src={Logo3} alt="Partner Logo 3" className="partnerlogo" />
                    <img src={Logo4} alt="Partner Logo 4" className="partnerlogo" />
                    <img src={Logo5} alt="Partner Logo 5" className="partnerlogo" />
                    <img src={Logo6} alt="Partner Logo 6" className="partnerlogo" />
                    {/* Duplicate the entire set for seamless looping */}
                    <img src={Logo1} alt="Partner Logo 1" className="partnerlogo" />
                    <img src={Logo2} alt="Partner Logo 2" className="partnerlogo" />
                    <img src={Logo3} alt="Partner Logo 3" className="partnerlogo" />
                    <img src={Logo4} alt="Partner Logo 4" className="partnerlogo" />
                    <img src={Logo5} alt="Partner Logo 5" className="partnerlogo" />
                    <img src={Logo6} alt="Partner Logo 6" className="partnerlogo" />
                </div>
            </div>
            </div>
            </div>

        </div>
      );
    }

export default HeroSection;
