import React from 'react';
import './Section.css'; // Specific styles for Section 1
import SvgThree from './SvgThree'; // Path to your SvgOne file
import SvgFour from './SvgFour'; // Path to your SvgTwo file

function Section1() {
  return (
    <section className="section2">
      <h1 className="mainHeadline">Harvesting Strength, Cultivating Trust 🥤 drop by drop</h1>
      <div className="subsection left">
        <div className="svgContainer">
          <SvgThree />
        </div>
        <div className="textContainer rightText">
        <h2>Strength & Support</h2>
  <p><strong>No-Code, Full Support:</strong> Let our platform be the hand that crafts your DeFi concoctions. From idea to deployment, we write the code so you don't have to — just pick the ingredients and let the magic happen.</p>
</div>
      </div>
      <div className="subsection right">
        <div className="textContainer leftText">
        <h2>Passion & Heart</h2>
    <p><strong>Guarding Your Heart:</strong> In DeFi, a broken heart is more than emotional — it's operational. That's why our security measures are the peel that keeps your investments juicy and safe, so you can invest with heart and peace of mind.</p>
  </div>
        <div className="svgContainer">
          <SvgFour />
        </div>
      </div>
    </section>
  );
}

export default Section1;
