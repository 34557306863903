import React from 'react';
import './Footer.css'; // Assuming you have a separate CSS file for footer styles

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>PARTNERS</h4>
          <ul>
            <li><a href="#!">Cardano Foundation</a></li>
            <li><a href="#!">EMURGO</a></li>
            <li><a href="#!">IOHK</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>MEDIA</h4>
          <ul>
            <li><a href="#!">Brand assets</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>SUPPORT</h4>
          <ul>
            <li><a href="#!">General Inquiries</a></li>
            <li><a href="#!">Partnerships</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>COMMUNITY</h4>
          <ul>
            <li><a href="#!">Forum</a></li>
            <li><a href="#!">Ambassadors</a></li>
            <li><a href="#!">Newsletter</a></li>
            <li><a href="#!">Social Channels</a></li>
            <li><a href="#!">Code of Conduct</a></li>
          </ul>
        </div>
        <div className="footer-section social-media">
          <h4>FOLLOW US</h4>
          {/* Add social media icons here */}
        </div>
      </div>
      <div className="footer-bottom">
        <p>© Cardano</p>
        <div className="footer-links">
          <a href="#!">Terms</a>
          <a href="#!">Privacy policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
