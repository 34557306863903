import React from 'react';
import './SharedSvgStyles.css'; // Ensure this path is correct for shared styles

function SvgTwo() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.05 233.05">
      <title>autodidact</title>
  <path className="orange2 juice2 infinite" d="M46.17 195.4h26.19v26.19H46.17z"/>
  <path className="orange2 juice2 animated  infinite" d="M46.17 195.4h26.19v26.19H46.17z"/>
  <path className="d juice2 animated infinite" d="M69.23 219.43h-19.7l-2.16-38.08h26.19l-4.33 38.08"/>
  <path className="d straw" d="M55.06 152.99h5.41v42.41h-5.41zm15.284-23.7l3.993 2.788-14.028 20.086-3.996-2.788zM55.06 150.27l5.26 1.9m-5.26-.46l5.76.81"/>
  <g>
  </g>
  <path className="d pattern" d="M137.79 123.86c-2.46 1.89-7.13 8.1-5.25 10.57l44.72 29.72a5.66 5.66 0 0 0 7.91 1.05 5.66 5.66 0 0 0 1.05-7.91l-33.32-37.17c-1.9-2.45-12.65 1.88-15.11 3.74z"/>
  <rect className="orange2 animated fadeOutDown infinite d2" x="53" y="125" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <rect className="orange2 animated fadeOutDown infinite" x="45" y="85" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <circle className="purple brain" cx="60.73" cy="118.85" r="10.16"/>
 <g className="brain">
    <path className="purple" d="M188.83 38.75a13.11 13.11 0 0 0-18.3-5.2 13.1 13.1 0 0 0-8.1-7.24c-.31-.1-.63-.18-.94-.26a13 13 0 0 0-7-5.19q-.61-.19-1.23-.32a13.11 13.11 0 0 0-16.69-3.88 13.32 13.32 0 0 0-1.91 1.22c-6-1.12-17.67-.83-20.08 4.82a13.2 13.2 0 0 0-11.3 5.66 13.06 13.06 0 0 0-6.66 1.5c-3.11 1.63-11 4.65-12 8l-.19.61c-4.88-.2-8.09 8.19-10.52 12.46a13.05 13.05 0 0 0-6.79 1.49C64.06 54 60.34 55 59.28 58.3l-12.81 9.29-.07.21a13 13 0 0 0-.26 6.8 13.14 13.14 0 0 0-1.25 2.72l-.22.64-.49-.17-.18-.07c-6.91-2.15-4.6 7.56-6.75 14.47A13.16 13.16 0 0 0 43 107.4a13.15 13.15 0 0 0 9 13.56l3.76 6.78.21.07a13.16 13.16 0 0 0 15.52-6.39 13 13 0 0 0 1.52-.18 13.19 13.19 0 0 0 2.76 1.22 13 13 0 0 0 10-.91 13.32 13.32 0 0 0 1.45-.88 13.36 13.36 0 0 0 .81 1.87c1.4 2.67 9.1-7.4 11.82-6.21a13.2 13.2 0 0 0 1.33 3.9 13 13 0 0 0 7.71 6.44c.31.1 10.55 6.69 10.87 6.76 1.66 2.48 17.07-1.28 20-.38 3.34 1 13.82-4.86 16.93-6.49l.21-.11c3.24.91 3.11 10.18 6.11 8.6h.06a13 13 0 0 0 6.88.17c1-.25.08 1.89 1 1.39a13.27 13.27 0 0 0 2.78-1.94 13.18 13.18 0 0 0 13-5.48 13.16 13.16 0 0 0 13.1-9.21l2-33.25a13.15 13.15 0 0 0-4.73-14.47l.12-.36.07-.21a13.15 13.15 0 0 0-6.52-15.59 13.25 13.25 0 0 0-.85-2.21l-.25-4.89.07-.21a13 13 0 0 0-.91-10.04z"/> 
    <path className="purple " d="M188.83 38.75a13.11 13.11 0 0 0-18.3-5.2 13.1 13.1 0 0 0-8.1-7.24c-.31-.1-.63-.18-.94-.26a13 13 0 0 0-7-5.19q-.61-.19-1.23-.32a13.11 13.11 0 0 0-16.69-3.88 13.32 13.32 0 0 0-1.91 1.22c-6-1.12-17.67-.83-20.08 4.82a13.2 13.2 0 0 0-11.3 5.66 13.06 13.06 0 0 0-6.66 1.5c-3.11 1.63-11 4.65-12 8l-.19.61c-4.88-.2-8.09 8.19-10.52 12.46a13.05 13.05 0 0 0-6.79 1.49C64.06 54 60.34 55 59.28 58.3l-12.81 9.29-.07.21a13 13 0 0 0-.26 6.8 13.14 13.14 0 0 0-1.25 2.72l-.22.64-.49-.17-.18-.07c-6.91-2.15-4.6 7.56-6.75 14.47A13.16 13.16 0 0 0 43 107.4a13.15 13.15 0 0 0 9 13.56l3.76 6.78.21.07a13.16 13.16 0 0 0 15.52-6.39 13 13 0 0 0 1.52-.18 13.19 13.19 0 0 0 2.76 1.22 13 13 0 0 0 10-.91 13.32 13.32 0 0 0 1.45-.88 13.36 13.36 0 0 0 .81 1.87c1.4 2.67 9.1-7.4 11.82-6.21a13.2 13.2 0 0 0 1.33 3.9 13 13 0 0 0 7.71 6.44c.31.1 10.55 6.69 10.87 6.76 1.66 2.48 17.07-1.28 20-.38 3.34 1 13.82-4.86 16.93-6.49l.21-.11c3.24.91 3.11 10.18 6.11 8.6h.06a13 13 0 0 0 6.88.17c1-.25.08 1.89 1 1.39a13.27 13.27 0 0 0 2.78-1.94 13.18 13.18 0 0 0 13-5.48 13.16 13.16 0 0 0 13.1-9.21l2-33.25a13.15 13.15 0 0 0-4.73-14.47l.12-.36.07-.21a13.15 13.15 0 0 0-6.52-15.59 13.25 13.25 0 0 0-.85-2.21l-.25-4.89.07-.21a13 13 0 0 0-.91-10.04z"/>
  <path className="d " d="M188.83 38.75a13.11 13.11 0 0 0-18.3-5.2 13.1 13.1 0 0 0-8.1-7.24c-.31-.1-.63-.18-.94-.26a13 13 0 0 0-7-5.19q-.61-.19-1.23-.32a13.11 13.11 0 0 0-16.69-3.88 13.32 13.32 0 0 0-1.91 1.22c-6-1.12-17.67-.83-20.08 4.82a13.2 13.2 0 0 0-11.3 5.66 13.06 -26.94 0 0 0-6.66 1.5c-3.11 1.63-11 4.65-12 8l-.19.61c-4.88-.2-8.09 8.19-10.52 12.46a13.05 13.05 0 0 0-6.79 1.49C64.06 54 60.34 55 59.28 58.3l-12.81 9.29-.07.21a13 13 0 0 0-.26 6.8 13.14 13.14 0 0 0-1.25 2.72l-.22.64-.49-.17-.18-.07c-6.91-2.15-4.6 7.56-6.75 14.47A13.16 13.16 0 0 0 43 107.4a13.15 13.15 0 0 0 9 13.56l3.76 6.78.21.07a13.16 13.16 0 0 0 15.52-6.39 13 13 0 0 0 1.52-.18 13.19 13.19 0 0 0 2.76 1.22 13 13 0 0 0 10-.91 13.32 13.32 0 0 0 1.45-.88 13.36 13.36 0 0 0 .81 1.87c1.4 2.67 9.1-7.4 11.82-6.21a13.2 13.2 0 0 0 1.33 3.9 13 13 0 0 0 7.71 6.44c.31.1 10.55 6.69 10.87 6.76 1.66 2.48 17.07-1.28 20-.38 3.34 1 13.82-4.86 16.93-6.49l.21-.11c3.24.91 3.11 10.18 6.11 8.6h.06a13 13 0 0 0 6.88.17c1-.25.08 1.89 1 1.39a13.27 13.27 0 0 0 2.78-1.94 13.18 13.18 0 0 0 13-5.48 13.16 13.16 0 0 0 13.1-9.21l2-33.25a13.15 13.15 0 0 0-4.73-14.47l.12-.36.07-.21a13.15 13.15 0 0 0-6.52-15.59 13.25 13.25 0 0 0-.85-2.21l-.25-4.89.07-.21a13 13 0 0 0-.91-10.04z"/>
   <path className="braind d " d="M152.32 124s1.95-33.79 31.07-41 15.37-3.11 15.37-3.11"/>
  <path className="braind d " d="M162.65 108.19a137 137 0 0 1 29.78-2"/>
  <path className="braind d  thic" d="M154.85 54.16c-.92.45-2.68 21.09-27.84 27.68S71.87 94.47 70 102.17s-3.42 12.55.54 13.29a28.71 28.71 0 0 1 5.89 1.6"/>
  <path className="braind  thic" d="M88.35 48.64s-6.36 14.55-2 18.28m42.84 25.37s-6.36 14.55-2 18.28"/>
  <path className="braind  thic" d="M176.14 46.18A17.33 17.33 0 1 1 143 35.88m-23.2 25.66a17.33 17.33 0 0 1 21.7-11.4M46 100.39A17.33 17.33 0 0 1 67.67 89m-1.2-17.41c8.67-4.06 20.44 1.37 24.5 10m90.96-2.99A17.33 17.33 0 0 1 159 70m3.87 41.76a17.33 17.33 0 0 1-22.95-8.62m-16.74-62.66a17.33 17.33 0 0 1-22.95-8.62m1.28 82.88c3.37-9 23.11-16.66 32.08-13.29"/>
    </g> 
    </svg>
    </div>
  );
}

export default SvgTwo;
