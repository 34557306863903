import React from 'react';
import './Section.css'; // Specific styles for Section 1
import SvgOne from './SvgOne'; // Path to your SvgOne file
import SvgTwo from './SvgTwo'; // Path to your SvgTwo file

function Section1() {
  return (
    <section  id="vision" className="section1">
      <h1 className="mainHeadline">🍋 Squeezing Simplicity & Intelligence into DeFi</h1>
      <div className="subsection left">
        <div className="svgContainer">
          <SvgOne />
        </div>
        <div className="textContainer rightText">
    <h2>Vision & Clarity</h2>
    <p><strong>Scan with Precision:</strong> Every smart contract is put under the lens for a thorough scan. Our open-source roots mean everything is clear as day — from contract creation to execution, your DeFi experience is free from pulp and full of clarity.</p>
</div>
      </div>
      <div className="subsection right">
      <div className="textContainer leftText">
    <h2>Intelligence & Adaptability</h2>
    <p><strong>Smart as a Whip:</strong> Tap into the brain-juice of DeFi with our AI-powered platform. It learns, adapts, and juices out the most beneficial strategies, ensuring you're always one sip ahead in the market.</p>
</div>
        <div className="svgContainer">
          <SvgTwo />
        </div>
      </div>
    </section>
  );
}

export default Section1;
