import React from 'react';
import './SharedSvgStyles.css'; // Assuming you have your shared styles here

function SvgOne() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.05 233.05">
        <defs> 
          <pattern id="a" patternUnits="userSpaceOnUse" width="4" height="4">
            <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" />
          </pattern>
        </defs>
        <title>animation</title>
        <path className="orangered" d="M187 110.786l13.41 10.563-6.912 8.774-13.41-10.563zM39.415 113.403l13.41 10.563-6.913 8.774-13.41-10.563z"/>
        <circle className="orange2 eye" cx="116.53" cy="123.07" r="40.5"/>
        <circle className="purple eye d3" cx="117.68" cy="123.07" r="18"/>
        <circle className="eye pattern d d4" cx="116.53" cy="123.07" r="16.67"/>
        <circle className="eye white d2" cx="135.68" cy="102.93" r="7.33"/>
        <path className="eye thic d" d="M116.53 168.32a90.46 90.46 0 0 0 78.39-45.25 90.53 90.53 0 0 0-156.78 0 90.46 90.46 0 0 0 78.39 45.25z"/>
        <circle className="eye white" cx="124.53" cy="122.23" r="5.55"/>
        <path className="d lash animated infinite flipInX" d="M23.97 95.53l14.12 11.12M33.29 87.63l10.24 9.45M110.66 57.15v13.66M95.42 58.33l3.67 13.65M66.75 67.15l9.67 12.38M45.04 79.53l9.49 9.15M55.21 72.44l7.7 10.77M82.42 60.43l3.33 11.55M209.09 95.53l-13.81 11.12M199.97 87.63l-10 9.45M124.53 57.37v13.66M139.34 59.33l-3.58 13.65M164.74 64.84l-9.45 12.38M188.49 79.53l-9.27 9.15M174.8 73.34l-7.52 10.77M149.04 62.43l-3.26 11.55"/>
      </svg>
    </div>
  );
}

export default SvgOne;
