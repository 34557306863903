import React from 'react';
import './SharedSvgStyles.css'; // Assuming you have your shared styles here

function SvgFour() {
  return (
    <div>
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.05 233.05">
  <defs>
         </defs>
  <path className="d draw pattern" d="M39.04 187.19h120.9v24.4H39.04z"/>
   <rect className="orangered animated fadeOutDown infinite d2" x="45" y="137.47" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <rect className="orangered animated fadeOutDown infinite" x="83" y="113.99" width="12.5" height="42" rx="6.25" ry="6.25"/>
  <path className="orangered animated pulse infinite d3" d="M128.95 61.66a37.72 37.72 0 0 0-26.67 11A37.84 37.84 0 0 0 37.76 99.5c0 20.42 48.93 61.81 59.35 93.92 35.87-19.1 69.68-73.5 69.68-93.92a37.84 37.84 0 0 0-37.84-37.84z"/><path className="d animated pulse infinite" d="M130.23 66.78a37.72 37.72 0 0 0-26.67 11A37.84 37.84 0 0 0 39 104.62c0 20.38 49 61.81 59.39 93.92 35.87-19.1 69.68-73.5 69.68-93.92a37.84 37.84 0 0 0-37.84-37.84z"/>
  <rect className="purple animated rubberBand infinite patch" x="88.7" y="83.71" width="51.04" height="51.04" rx="11.62" ry="11.62"/>
  <path className="d animated swing infinite" d="M97.29 21.46v13.65M85.05 24.63l3.66 13.66m-35.33-6.83l9.67 12.38m-17.36-6.19l7.69 10.76m18.67-21.68l3.33 11.56m35.78-16.61v13.65m11.81-10.7l-3.59 13.66m34.53-6.83l-9.45 12.38m16.96-6.19l-7.51 10.76m-18.24-21.68l-3.26 11.56m54.61 22.28l-12.78 4.81m5.5-15.15l-11.48 8.24m23.44 15.15l-12.78 4.81m14.18 7.29l-14.04 1.45m18.54 29.91l-14.91-4.48m11.77 13.7l-12.73-3.25m13.87-24.7l-11.95 1.02"/>
  <path className="d animated rubberBand infinite patch" d="M80.27 91.14h16.67m-16.67 5.33h16.67m-16.67 5.33h16.67m-16.67 5.34h16.67m-16.67 5.33h16.67m-16.67 5.33h16.67m-16.67 5.34h16.67m32-46.86v14.86m-5.33-14.86v14.86m-5.34-14.86v14.86m-5.33-14.86v14.86m-5.33-14.86v14.86m-5.34-14.86v14.86m-5.33-14.86v14.86m55.28 34.09h-16.66m16.66-5.33h-16.66m16.66-5.34h-16.66m16.66-5.33h-16.66m16.66-5.33h-16.66m16.66-5.34h-16.66m16.66-5.33h-16.66m-32 46.86v-14.86m5.33 14.86v-14.86m5.33 14.86v-14.86m5.34 14.86v-14.86m5.33 14.86v-14.86m5.33 14.86v-14.86m5.34 14.86v-14.86"/>
      </svg>
    </div>
  );
}

export default SvgFour;
