import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Menu = () => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    const handleScroll = () => {
        let currentScroll = window.scrollY || document.documentElement.scrollTop;
    
        if (currentScroll > lastScrollTop) {
            // Scrolling down, hide the menu
            setIsVisible(false);
        } else if (currentScroll < lastScrollTop) {
            // Scrolling up, show the menu
            setIsVisible(true);
        }
    
        // Clear our timeout throughout the scroll
        clearTimeout(scrollTimeout);
    
        // Set a timeout to run after scrolling ends
        setScrollTimeout(setTimeout(function() {
            // Check if the user has stopped scrolling (currentScroll is the same)
            // and if the menu isn't already visible.
            if (currentScroll === window.scrollY && !isVisible) {
                setIsVisible(true); // Show the menu
            }
        }, 100));
    
        setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll);
    };
    
    const smoothScroll = (e) => {
      e.preventDefault(); // Prevent default anchor behavior
      const targetId = e.currentTarget.getAttribute("href").slice(1); // Get the target id (removing the '#')
      const targetElement = document.getElementById(targetId); // Find the target element

      if (targetElement) {
          targetElement.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'start' 
          });
      }
  };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(scrollTimeout); // Clean up the timeout on component unmount
        };
    }, [lastScrollTop, isVisible, scrollTimeout]);

    return (
        <div className={`menu ${!isVisible ? 'hide' : 'fadeInMenu'}`}>
      <div className="menu-content">
        <div className="logo">Logo</div>
        <div className="menu-items">
        <a href="#vision" onClick={smoothScroll}>Vision</a>
        <a href="#what" onClick={smoothScroll}>About</a>
          <a href="#start" onClick={smoothScroll}>Get Started</a>
          <a href="#faq" onClick={smoothScroll}>FAQ</a>
          <a href="https://docs.juicemarket.org" target="_blank" rel="noopener noreferrer">Docs</a>
          <a href="https://app.juice.market" target="_blank" rel="noopener noreferrer">Dapp</a>
          <a href="https://paper.juicemarket.org" target="_blank" rel="noopener noreferrer">Litepaper</a>
                </div>
        <div className="social-icons">
                    <a href="https://t.me/placeholder" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a href="https://discord.gg/placeholder" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a href="https://twitter.com/placeholder" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </div>
        <div className="menu-icon">
          {/* Simple SVG for sandwich menu */}
          <svg height="30" width="30">
            <path d="M0,5 30,5" stroke="#333" strokeWidth="5"/>
            <path d="M0,14 30,14" stroke="#333" strokeWidth="5"/>
            <path d="M0,23 30,23" stroke="#333" strokeWidth="5"/>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Menu;
